import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  Input,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatSidenavContent} from '@angular/material/sidenav';
import {
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent
} from '@angular/router';
import {Subject} from 'rxjs';
import {
  AccessControlService
} from 'src/app/core/deprecated/deprecated-services/deprecated-access/access-control.service';
import {DashboardService} from 'src/app/core/services/dashboard/dashboard.service';
import {ToggleMenuService} from './toggle-menu.service';
import {AuthenticationsService} from "../../../core/services/auth/authenticationsService";
import {UserAccessService} from "../../../core/services/user-access/user-access.service";
import {MenuLink} from "../../../core/models/menu/menu-link";
import {AccessManagementService} from "../../../core/services/user-access/access-management.service";
import {MenuLinkService} from "../../../core/services/menu-link/menu-link.service";
import {MenuLinksManagementService} from "../../../core/services/menu-link/menu-links-management.service";
import {UserSpace} from "../../../core/models/user-access/user.enum";

declare var require: any;
const {version} = require('../../../../../package.json');

@Component({
  selector: 'sg-content',
  templateUrl: './app-content.component.html',
  styleUrls: ['./app-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppContentComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @Input("isStandalone") isStandalone: boolean = false;
  public isShowingRouteLoadIndicator: boolean;
  metionsFr = 'https://www.smartglobe.fr/mentions-legales/';
  mentionsMa = 'https://visioprocess.ma/conditions-generales-de-service-et-dutilisation/';
  smartkizTermsURL = 'https://smartkiz.fr/';
  metions =this.metionsFr;
  menuSettings: any = {
    title: {
      icon: '',
      text: ''
    },
    navMenu: {
      showButton: true,
      expanded: false
    },
    infoMenu: {
      showButton: true,
      expanded: false,
      enum: -1
    },
    editMenu: {
      showButton: false
    },
    selectedPath: {
      i: 0,
      j: -1
    }
  };

  currentVersion = '';
  links: MenuLink[] = [];
  currentBuilding: any = {views: []};
  loading = false;
  generalViews = [];
  isFullScreen = false;

  @Input() menuSettingsObservable: Subject<any[]>;
  @ViewChild('content', {static: true}) public drawer: MatSidenavContent;

  constructor(
    private router: Router,
    public authService: AuthenticationsService,
    private homeNavService: DashboardService,
    private accessService: AccessControlService,
    private renderer: Renderer2,
    private readonly ngZone: NgZone,
    private userAccessService: UserAccessService,
    private accessManagementService: AccessManagementService,
    private menuLinkService: MenuLinkService,
    public menuLinksManagementService: MenuLinksManagementService,
    private toggleMenuService: ToggleMenuService,

  ) {
    this.router.events.subscribe((val: NavigationStart) => {
      if (
        val.url &&
        !(val.url.includes('interventions') || val.url.includes('stock'))
      ) {
        window.onresize = e => {
          this.ngZone.run(() => {
            if (
              this.menuSettings &&
              this.menuSettings.infoMenu &&
              this.menuSettings.infoMenu.showButton
            ) {
              if (window.innerWidth <= 900) {
                this.menuSettings.infoMenu.expanded = false;

              } else if (this.router.url.includes('equipment-scada') || this.router.url.includes('equipment-control') || this.router.url.includes('dashboard')) {
                this.menuSettings.infoMenu.expanded = false;
              } else {
                this.menuSettings.infoMenu.expanded = true;
              }
            }
          });
        };
      }

      if (val.url && val.url.includes('general')) {
        this.currentBuilding = {
          buildingId: 'general',
          currentBuildingId: 'general',
          name: 'General',
          views: this.generalViews
        };
      }

      if (val instanceof NavigationEnd) {
        const url = val.url;

        if (
            (url.includes('reception-window')) ||
            (url.includes('weather-window')) ||
            (url.includes('login')) ||
            (url.includes('register')) ||
            (url.includes('reset'))
        ) {
          console.log(val.url);
          this.isFullScreen = true;
        } else {
          this.isFullScreen = false;
        }
      }
    });

    window.onresize = e => {

      this.ngZone.run(() => {
        if (this.menuSettings.infoMenu.showButton) {
          if (window.innerWidth <= 900) {
            this.menuSettings.infoMenu.expanded = false;
          } else {
            this.menuSettings.infoMenu.expanded = true;
          }
        }
      });
    };
    this.subscribeChangePage();
  }

  initTermsUrl(){
      let client = this.authService.getClientId();
      if(client == 'loreal' ){
        this.metions = this.mentionsMa;
      }

    this.authService.isSmartkizObs.subscribe(data=>{
      data && (this.metions = this.smartkizTermsURL);
    })
  }

  subscribeChangePage() {
    this.isShowingRouteLoadIndicator = false;
    this.router.events.subscribe(
      ( event: RouterEvent ) : void => {
        if ( event instanceof RouteConfigLoadStart ) {
          this.isShowingRouteLoadIndicator = true;
        } else if ( event instanceof RouteConfigLoadEnd ) {
          this.isShowingRouteLoadIndicator = false;
        }
      }
    );
  }

  ngOnInit() {
    this.links = [];
    this.currentVersion = version;

    this.toggleMenuService.actionMessage$.subscribe((action: boolean) => {
      this.menuSettings.infoMenu.expanded = action;
    });

    this.loading = true;

    this.menuSettingsObservable.subscribe(resSettings => {
      if (resSettings instanceof Array) {
        this.menuSettings = {
          navMenu: {
            showButton: true,
            expanded: false
          }
        };
      } else {
        this.menuSettings = resSettings;

        this.homeNavService.dashboardSelectedSiteObs.subscribe(
          (building: any) => {
            if (
              this.menuSettings.title.icon === 'map' ||
              this.menuSettings.title.icon === 'location_on'
            ) {
              this.menuSettings.title.text = building?.name;
            }
          },
          error => {
            this.menuSettings.title.text = 'General';

            console.log(error);
          }
        );
      }
    });

    this.authService.isAuthenticatedObs.subscribe(
      isLoggedIn => {
        if (isLoggedIn && !this.links.length) {
          this.initTermsUrl();
        } else if (!isLoggedIn && this.links.length) {
          this.links = [];
        }
      })
    this.subscribeLogin();
  }


  private subscribeLogin() {
    this.authService.isAuthenticatedObs.subscribe((data) => {
      if (data) this.firstView = true;
    })
  }

  public firstView = true;

  ngAfterContentChecked(): void {
    let sidenav = document.getElementById('side-nav');
    if (this.firstView && sidenav) {
      this.firstView = false;
      this.addSidenavListener();
    }
  }

  ngAfterViewInit(): void {
    this.addSidenavListener();
  }

  private addSidenavListener() {
    let sidenav = document.getElementById('side-nav');
    let drawer = sidenav?.firstChild as HTMLElement;

    if (!drawer || !drawer.classList.contains('mat-drawer-inner-container')) return
    this.firstView = false;
    drawer.addEventListener('mouseleave', this.sidenavMouseLeaveHandler);
    drawer.addEventListener('mouseover', this.sidenavMouseOverHandler);
  }


  private sidenavMouseOverHandler = () => {
    let sidenav = document.getElementById('side-nav');
    let drawer = sidenav?.firstChild as HTMLElement;
    drawer.classList.remove('over-flow-y-hidden');
    drawer.classList.add('over-flow-y-auto');
  }

  private sidenavMouseLeaveHandler = () => {
    let sidenav = document.getElementById('side-nav');
    let drawer = sidenav?.firstChild as HTMLElement;
    setTimeout(function () {
      drawer.classList.add('over-flow-y-hidden');
      drawer.classList.remove('over-flow-y-auto');
    }, 300);
  }

  closeMenu() {
    this.menuSettings.navMenu.expanded = false;
  }

}

import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate} from "@angular/router";
import {AuthenticationsService} from "../services/auth/authenticationsService";
import {AuthorizationSpace} from "../models/app/authorizationNameSpace";
import {switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {MenuLinkService} from "../services/menu-link/menu-link.service";
import PagesTypes = AuthorizationSpace.PagesTypes;
import {LocalStorageKeysEnum} from "../services/localstorage/local-storage-keys.enum";
import {LocalStorageService} from "../services/localstorage/local-storage.service";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthenticationsService,
              private localStorageService: LocalStorageService,
              private menuLinkService: MenuLinkService) {
  }


  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      this.authService.signOut();
      return false;
    }

    return this.menuLinkService.getAuthorizedPagesObs.pipe(
      switchMap((pageTypes: PagesTypes[]) => {
        let isPageAllowed = pageTypes.includes(route.data['pageType']) || route.data['pageType'] == 'ALLOWED'
        const isStandalone = this.authService.checkStandAlone(route) && route.data['canStandalone'];
        const authorized = isPageAllowed || isStandalone;

        if (!authorized) {
          this.authService.signOut();
        }

        return of(authorized);
      })
    );
  }
}

import {Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {ActivatedRoute, ActivationStart, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DashboardService} from './core/services/dashboard/dashboard.service';
import {ThemeService} from './core/services/theme/theme.service';
import {GmaoTicketsService} from './core/services/ticket/gmao-tickets.service';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthenticationsService} from "./core/services/auth/authenticationsService";
import {environment} from "../environments/environment";
import {LocalStorageKeysEnum} from "./core/services/localstorage/local-storage-keys.enum";
import {JwtHelperService} from "@auth0/angular-jwt";

// tslint:disable-next-line:no-implicit-dependencies no-submodule-imports
@Component({
  selector: 'gmao-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') public drawer: MatDrawer;
  mobileMode: boolean = false;
  subscriptions = new Subscription();
  menuSettings = {
    title: {
      icon: '',
      text: ''
    },
    navMenu: {
      showButton: true,
      expanded: false
    },
    infoMenu: {
      showButton: true,
      expanded: false,
      enum: -1
    },
    editMenu: {
      showButton: false
    },
    selectedPath: {
      i: 0,
      j: -1
    }
  };

  isSpecialUrl = false;
  isReceptionScreen = false;

  private _menuSettings = new BehaviorSubject<any>([]);
  menuSettingsObservable = this._menuSettings.asObservable();

  constructor(
    private router: Router,
    public authService: AuthenticationsService,
    private ticketsService: GmaoTicketsService,
    private translate: TranslateService,
    private cookie: CookieService,
    private jwtHelper: JwtHelperService,
    private homeNavService: DashboardService,
    private themeService: ThemeService,
    private activatedRouter: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer) {
    this.router.events.subscribe((change: any) => {
      if (change instanceof ActivationStart) {
        let clientId: string = change.snapshot.queryParams['clientId'];
        clientId && this.cookie.set(LocalStorageKeysEnum.X_CLIENT_ID, clientId, {
          path: '/',
          expires: 365, // Adjust expiration as needed
          sameSite: 'None', // Ensure this is set for cross-site usage
          secure: true // Set to true for production; remove for local testing over HTTP
        })
      } else if (change instanceof NavigationStart) {
        // Use URLSearchParams to extract the clientId
        const params = new URLSearchParams(change.url.split('?')[1]);
        const clientId = params.get('clientId');
        clientId && this.cookie.set(LocalStorageKeysEnum.X_CLIENT_ID, clientId, {
          path: '/',
          expires: 365, // Adjust expiration as needed
          sameSite: 'None', // Ensure this is set for cross-site usage
          secure: true // Set to true for production; remove for local testing over HTTP
        })
      }

      if (Object.keys(change).includes('url')) {
        this.isSpecialUrl = change.url.includes('login');
      }
    });
    this.themeService.setTheme();
    this.setMenus();
    this.addGoogleScript();

  }

  private addGoogleScript() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.googleAPIKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  ngOnInit() {
    this.IframeMessageListener();
    this.themeService.setTheme();
    this.authService.isAuthenticatedObs.subscribe(
      isLoggedIn => {
        if (isLoggedIn) {
          this.authService.checkSmartkiz();
          this.ticketsService.getClientMetaData().subscribe(
            metaData => {
              this.ticketsService.clientMeta = metaData;
            },
            error => {
              console.log(error);
            }
          );
        }
      },
      error => {
        console.log(error);
      }
    );

    this.homeNavService.sideNavOpened.subscribe(
      value => {
        if (this.drawer && window.innerWidth <= 900) this.drawer.opened = value;
      },
      error => {
        console.log(error);
      }
    );

    if (window.innerWidth <= 900) {
      this.mobileMode = true;
      this.menuSettings.infoMenu.expanded = false;
    } else {
      this.mobileMode = false;
      this.menuSettings.infoMenu.expanded = true;
    }

    this.newMatIconRegistration()
  }


  private IframeMessageListener = () =>{
    console.log("Call Function")
    addEventListener('message', (e) => {
      let token: string = '';
      if (e.data && typeof e.data === 'string') {
      console.log("inside if", e.data);
        try {
          token = JSON.parse(e.data)?.['authToken'];
          console.log("token1111", token);
          console.log("isTokenExpired", this.jwtHelper.isTokenExpired(token));
          if (!token || this.jwtHelper.isTokenExpired(token)) return;
          console.log("token22222", token);
          if(!this.authService.getToken()){
            this.authService.setTokenToCookie(token);
            window.location.reload();
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    })
  }
  setMenus() {
    this.menuSettings = {
      title: {
        icon: '',
        text: ''
      },
      navMenu: {
        showButton: true,
        expanded: false
      },
      infoMenu: {
        showButton: true,
        expanded: false,
        enum: -1
      },
      editMenu: {
        showButton: false
      },
      selectedPath: {
        i: 0,
        j: -1
      }
    };
    this.translate.get('dummy');
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(
        event => {
          if (event.url.includes('login') || event.url.includes('register')) {
            this.isSpecialUrl = true;
            this.menuSettings = {
              title: {
                icon: '',
                text: ''
              },
              navMenu: {
                showButton: false,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: -1,
                j: -1
              }
            };
          } else if (event.url.includes('/dashboard') && !event.url.includes('/t/') && !event.url.includes('/w/')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Sites',
                icon: 'map'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: true,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 0,
                j: -1
              }
            };
            if (window.innerWidth <= 900) {
              this.menuSettings.infoMenu.expanded = false;
            } else {
              this.menuSettings.infoMenu.expanded = false;
            }
          } else if (event.url.includes('/dashboard') && (event.url.includes('/t/') || event.url.includes('/w/'))) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Sites',
                icon: 'map'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: true,
                expanded: true,
                enum: -1
              },
              editMenu: {
                showButton: true
              },
              selectedPath: {
                i: 0,
                j: -1
              }
            };
            if (window.innerWidth <= 900) {
              this.menuSettings.infoMenu.expanded = false;
            } else {
              this.menuSettings.infoMenu.expanded = true;
            }
          } else if (event.url.includes('building-locations')) {
            this.isSpecialUrl = false;
            this.isReceptionScreen = false;
            this.menuSettings = {
              title: {
                text: "locations",
                icon: 'text_snippet'
              },
              navMenu: {
                showButton: false,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 1,
                j: -1
              }
            };
          } else if (event.url.includes('gmao') && !event.url.includes('gmao-indicators')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: "Saisie des demandes d'intervention",
                icon: 'text_snippet'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 1,
                j: -1
              }
            };
          } else if (event.url.includes('gmao-indicators')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: "Saisie des demandes d'intervention",
                icon: 'text_snippet'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: true
              },
              selectedPath: {
                i: 1,
                j: -1
              }
            };
          } else if (event.url.includes('interventions') && !event.url.includes('interventions-indicators')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Planification des interventions',
                icon: 'build'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 2,
                j: -1
              }
            };
          } else if (event.url.includes('interventions-indicators')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Planification des interventions',
                icon: 'build'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: true
              },
              selectedPath: {
                i: 2,
                j: -1
              }
            };
          } else if (event.url.includes('equipment') &&
            !event.url.includes('equipment-scada') &&
            !event.url.includes('equipment-control')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Equipments',
                icon: 'hvac'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 3,
                j: 0
              }
            };
          } else if (event.url.includes('maintenance')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Maintenance',
                icon: 'handyman'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 3,
                j: 1
              }
            };
          } else if (event.url.includes('parts')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Parts',
                icon: 'store'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 3,
                j: 2
              }
            };
          } else if (event.url.includes('warehouses')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Warehouses',
                icon: 'store'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 3,
                j: 2
              }
            };
          } else if (event.url.includes('explorer')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Explorer',
                icon: 'folder'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 4,
                j: 0
              }
            };
          } else if (event.url.includes('bills')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.BILLS'),
                icon: 'request_quote'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 4,
                j: 1
              }
            };
          } else if (event.url.includes('expenses')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.EXPENSES'),
                icon: 'euro_symbol'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 4,
                j: 2
              }
            };
          } else if (event.url.includes('sites')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.SITES'),
                icon: 'apartment'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 5,
                j: 0
              }
            };
          } else if (event.url.includes('access/reset')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = true;

            this.menuSettings = {
              title: {
                text: 'Users',
                icon: 'groups'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 5,
                j: 1
              }
            };
          } else if (event.url.includes('access')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.USERS_ACCESS'),
                icon: 'groups'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 5,
                j: 1
              }
            };
          } else if (event.url.includes('view') && !event.url.includes('reporting-view')
            && !event.url.includes('new_view')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Views',
                icon: 'widgets'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 5,
                j: 2
              }
            };
          } else if (event.url.includes('alarms')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.ALARMS'),
                icon: 'alarm'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 5,
                j: 3
              }
            };
          } else if (event.url.includes('general')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Reporting',
                icon: 'analytics'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: 3
              },
              editMenu: {
                showButton: true
              },
              selectedPath: {
                i: 7,
                j: -1
              }
            };
          } else if (
            (event.url.includes('dashboard') &&
              !event.url.includes('reporting')) ||
            event.url.includes('schemas')
          ) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'building_name',
                icon: 'location_on'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: true,
                expanded: true,
                enum: 3
              },
              editMenu: {
                showButton: true
              },
              selectedPath: {
                i: -1,
                j: -1
              }
            };
          } else if (event.url.includes('informations')) {
            console.log('In >>>>: ', 'informations');
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'building_name',
                icon: 'location_on'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: true,
                expanded: true,
                enum: 3
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: -1,
                j: -1
              }
            };
          } else if (event.url.includes('alarm-visualisation')) {
            console.log('In >>>>: ', 'alarm-visualisation');
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'building_name',
                icon: 'location_on'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: true,
                expanded: true,
                enum: 4
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: -1,
                j: -1
              }
            };
          } else if (event.url.includes('equipment-scada')) {
            console.log('In >>>>: ', 'equipment-scada');
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'building_name',
                icon: 'location_on'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: true,
                expanded: false,
                enum: 3
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: -1,
                j: -1
              }
            };

          } else if (event.url.includes('equipment-control')) {
            console.log('In >>>>: ', 'equipment-control');
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            this.menuSettings = {
              title: {
                text: 'building_name',
                icon: 'location_on'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: true,
                expanded: false,
                enum: 3
              },
              editMenu: {
                showButton: true
              },
              selectedPath: {
                i: -1,
                j: -1
              }
            };
          } else if (event.url.includes('reporting-view')) {
            console.log('In >>>>: ', 'reporting-view');
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'building_name',
                icon: 'location_on'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: true,
                expanded: true,
                enum: 3
              },
              editMenu: {
                showButton: true
              },
              selectedPath: {
                i: -1,
                j: -1
              }
            };
          } else if (event.url.includes('user-profile')) {
            console.log('In >>>>: ', 'profile');
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.PROFILE'),
                icon: 'person'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 6,
                j: -1
              }
            };
          } else if (event.url.includes('reception-window')) {
            console.log('In >>>>: ', 'reception-window');
            this.isReceptionScreen = true;
            this.isSpecialUrl = true;

            this.menuSettings = {
              title: {
                text: 'Reception Window',
                icon: ''
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 6,
                j: -1
              }
            };
          } else if (event.url.includes('weather-window')) {
            console.log('In >>>>: ', 'weather-window');
            this.isReceptionScreen = true;
            this.isSpecialUrl = true;

            this.menuSettings = {
              title: {
                text: 'Weather Window',
                icon: ''
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 6,
                j: -1
              }
            };
          } else if (event.url.includes('admin/energy-page')) {
            console.log('In >>>>: ', 'energy');
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: 'Energy',
                icon: 'emoji_objects'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 5,
                j: 4
              }
            };
          } else if (event.url.includes('water-building-consumption')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let name: string = event.url.split('/')[4];

            let translatedTitle = this.translate.instant('PAGES_TITLES.WATER_SITE_INSIGHTS')

            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'leaderboard'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 8,
                j: 1
              }
            };
          } else if (event.url.includes('building-consumption')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let name: string = event.url.split('/')[4];

            let translatedTitle = this.translate.instant('PAGES_TITLES.CONSUMPTION_SUMMARY')

            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'leaderboard'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 7,
                j: 1
              }
            };
          } else if (event.url.includes('energy-consumption-analysis')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let translatedTitle = this.translate.instant('PAGES_TITLES.ENERGY_CONSUMPTION_ANALYSIS')

            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'stacked_line_chart'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 7,
                j: 2
              }
            };
          } else if (event.url.includes('water-consumption-analysis')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let translatedTitle = this.translate.instant('PAGES_TITLES.WATER_CONSUMPTION_ANALYSIS')

            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'waterfall_chart'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 8,
                j: 2
              }
            };
          } else if (event.url.includes('energy-consumption')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let translatedTitle = this.translate.instant('PAGES_TITLES.ENERGY_CONSUMPTION')

            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'electric_bolt'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 7,
                j: 0
              }
            };
          } else if (event.url.includes('water-consumption')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let translatedTitle = this.translate.instant('PAGES_TITLES.WATER_CONSUMPTION')

            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'water_drop'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 8,
                j: 0
              }
            };
          } else if (event.url.includes('energy-meters')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let translatedTitle = this.translate.instant('PAGES_TITLES.ENERGY_METERS')
            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'device_thermostat'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 5,
                j: 4
              }
            };
          } else if (event.url.includes('meters-contracts-management')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let translatedTitle = this.translate.instant('PAGES_TITLES.ENERGY_CONTRACTS')
            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'draw'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 5,
                j: 5
              }
            };
          } else if (event.url.includes('scheduling')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let translatedTitle = this.translate.instant('PAGES_TITLES.SCHEDULING')

            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'edit_calendar'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 9,
                j: 1
              }
            };
          } else if (event.url.includes('planning-templates')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;
            let translatedTitle = this.translate.instant('PAGES_TITLES.PLANNING_TEMPLATES')

            this.menuSettings = {
              title: {
                text: translatedTitle,
                icon: 'alarm_add'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 9,
                j: 0
              }
            };
          } else if (event.url.includes('templates')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.REPORTS_TEMPLATES'),
                icon: 'document_scanner'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 4,
                j: 2
              }
            };
          } else if (event.url.includes('ifttt')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.REPORTS_TEMPLATES'),
                icon: 'document_scanner'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 4,
                j: 2
              }
            };
          } else if (event.url.includes('reports')) {
            this.isReceptionScreen = false;
            this.isSpecialUrl = false;

            this.menuSettings = {
              title: {
                text: this.translate.instant('PAGES_TITLES.REPORTS'),
                icon: 'web'
              },
              navMenu: {
                showButton: true,
                expanded: false
              },
              infoMenu: {
                showButton: false,
                expanded: false,
                enum: -1
              },
              editMenu: {
                showButton: false
              },
              selectedPath: {
                i: 4,
                j: 2
              }
            };
          }

          this._menuSettings.next(this.menuSettings);
        },
        error => {
          console.log(error);
        }
      );
  }

  private newMatIconRegistration() {
    this.matIconRegistry.addSvgIcon(
      'remote_control',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/controller.svg')
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 900) {
      this.mobileMode = true;
    } else {
      this.mobileMode = false;
    }
  }

  getCurrentMatTheme() {
    return this.themeService.getActiveMatTheme();
  }
}

export interface IClientMetaData {
  roles: [];
  statuses: [];
  statusWorkflow: [];
  statusAuthorization: [];
}

export interface ITabItem {
  label: string;
  path: string;
  disabled: boolean;
  subPaths: ITabItem[];
  subViews: ITabItem[];
  name: string;
}

import {UserSpace} from "../user-access/user.enum";

export namespace AuthorizationSpace {

  import UserRoleEnum = UserSpace.UserRoleEnum;
  export type PagesTypes = 'DASHBOARD' | 'GMAO' | 'GMAO_TICKETS' | 'GMAO_INDICATORS' |
    'INTERVENTIONS' | 'INTERVENTIONS_TICKETS' | 'INTERVENTIONS_INDICATORS' | 'EQUIPMENTS' |
    'MAINTENANCE' | 'EXPLORER' | 'BILLS' | 'EXPENSES' | 'SITES' | 'USERS' | 'VIEWS' | 'ALARMS' |
    'ENERGY' | 'PROFILE' | 'SCHEDULING' | 'RECEPTION' | 'WEATHER_STATION' | 'ENERGY_METERS' | 'ENERGY_CONSUMPTION' |
    'ENERGY_CONSUMPTION_ANALYSIS' | 'WATER_CONSUMPTION' | 'WATER_CONSUMPTION_ANALYSIS' | 'ENERGY_CONTRACTS' |
    'WATER_SITE_INSIGHTS' | 'ENERGY_SITE_INSIGHTS' | 'REPORTS_TEMPLATES' | 'REPORTS' | 'SCENARIO_TEMPLATES' | 'SCENARIO' | 'SCENARIO_PLANNING';

  export const PagesEnum = {
    DASHBOARD: 'DASHBOARD' as PagesTypes,
    GMAO: 'GMAO' as PagesTypes,
    GMAO_TICKETS: 'GMAO_TICKETS' as PagesTypes,
    GMAO_INDICATORS: 'GMAO_INDICATORS' as PagesTypes,
    INTERVENTIONS: 'INTERVENTIONS' as PagesTypes,
    INTERVENTIONS_TICKETS: 'INTERVENTIONS_TICKETS' as PagesTypes,
    INTERVENTIONS_INDICATORS: 'INTERVENTIONS_INDICATORS' as PagesTypes,
    EQUIPMENTS: 'EQUIPMENTS' as PagesTypes,
    MAINTENANCE: 'MAINTENANCE' as PagesTypes,
    EXPLORER: 'EXPLORER' as PagesTypes,
    BILLS: 'BILLS' as PagesTypes,
    EXPENSES: 'EXPENSES' as PagesTypes,
    ALARMS: 'ALARMS' as PagesTypes,
    SITES: 'SITES' as PagesTypes,
    USERS: 'USERS' as PagesTypes,
    VIEWS: 'VIEWS' as PagesTypes,
    ENERGY: 'ENERGY' as PagesTypes,
    PROFILE: 'PROFILE' as PagesTypes,
    SCHEDULING: 'SCHEDULING' as PagesTypes,
    RECEPTION: 'RECEPTION' as PagesTypes,
    WEATHER_STATION: 'WEATHER_STATION' as PagesTypes,
    ENERGY_METERS: 'ENERGY_METERS' as PagesTypes,
    ENERGY_CONSUMPTION: 'ENERGY_CONSUMPTION' as PagesTypes,
    ENERGY_CONSUMPTION_ANALYSIS: 'ENERGY_CONSUMPTION_ANALYSIS' as PagesTypes,
    WATER_CONSUMPTION: 'WATER_CONSUMPTION' as PagesTypes,
    WATER_CONSUMPTION_ANALYSIS: 'WATER_CONSUMPTION_ANALYSIS' as PagesTypes,
    ENERGY_CONTRACTS: 'ENERGY_CONTRACTS' as PagesTypes,
    WATER_SITE_INSIGHTS: 'WATER_SITE_INSIGHTS' as PagesTypes,
    ENERGY_SITE_INSIGHTS: 'ENERGY_SITE_INSIGHTS' as PagesTypes,
    REPORTS_TEMPLATES: 'REPORTS_TEMPLATES' as PagesTypes,
    REPORTS: 'REPORTS' as PagesTypes,
    SCENARIO_TEMPLATES: 'SCENARIO_TEMPLATES' as PagesTypes,
    SCENARIO: 'SCENARIO' as PagesTypes,
    SCENARIO_PLANNING: 'SCENARIO_PLANNING' as PagesTypes
  }

  export const SuperAdminAuthorizedPages: PagesTypes[] = [
    'DASHBOARD', 'GMAO', 'GMAO_TICKETS' , 'GMAO_INDICATORS' , 'INTERVENTIONS' , 'INTERVENTIONS_TICKETS' ,
    'INTERVENTIONS_INDICATORS', 'EQUIPMENTS', 'MAINTENANCE', 'EXPLORER',
    'BILLS', 'EXPENSES', 'SITES', 'USERS', 'VIEWS', 'ENERGY', 'PROFILE', 'SCHEDULING', 'ALARMS',
    'ENERGY_METERS', 'ENERGY_CONSUMPTION', 'ENERGY_CONSUMPTION_ANALYSIS', 'WATER_CONSUMPTION', 'WATER_CONSUMPTION_ANALYSIS', 'ENERGY_CONTRACTS',
    'WATER_SITE_INSIGHTS', 'ENERGY_SITE_INSIGHTS', "REPORTS_TEMPLATES", "REPORTS", "WEATHER_STATION", "SCENARIO_TEMPLATES", "SCENARIO", "SCENARIO_PLANNING"
  ]

  export const userLandingPage: { [key: string]: { [key: string]: string } } = {
    [UserRoleEnum.SUPER_ADMIN]: {
      default: 'dashboard'
    },
    [UserRoleEnum.ADMIN]: {
      default: 'dashboard'
    },
    [UserRoleEnum.OPERATOR]: {
      default: 'dashboard'
    },
    [UserRoleEnum.USER]: {
      'capdeseine': 'dashboard',
      default: 'gmao'
    },
    [UserRoleEnum.SCREEN]: {
      'hoganlovells': 'weather-window',
      default: 'dashboard'
    }
  }
}




import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {Menu} from "../../models/menu/menu";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {AuthenticationsService} from "../auth/authenticationsService";
import {UserSpace} from "../../models/user-access/user.enum";
import {MenuLinksManagementService} from "./menu-links-management.service";
import {AuthorizationSpace} from "../../models/app/authorizationNameSpace";
import AuthedUserAttributesEnum = UserSpace.AuthedUserAttributesEnum;
import PagesTypes = AuthorizationSpace.PagesTypes;

@Injectable({providedIn: 'root'})
export class MenuLinkService {

  constructor(private http: HttpClient,
              private authenticationsService:AuthenticationsService,
              private menuLinksManagementService: MenuLinksManagementService) {}

  getMenu(featureActive: boolean, featureRole: number): Observable<PagesTypes[]> {
    let utl = `/api/sg-users/`;
    let params = new HttpParams().append('featureActive', featureActive +"").append('featureRole', featureRole.toString());
    return this.http.get<Menu>(utl, {params: params}).pipe(
      map((menu: Menu)=> this.menuLinksManagementService.setupMenuLinks(menu))
    )
  }

  get getAuthorizedPagesObs(): Observable<PagesTypes[]> {
    let role = +this.authenticationsService.getAuthedUserData(AuthedUserAttributesEnum.ROLE);
    if (this.menuLinksManagementService.authorizedPages.length) return of(this.menuLinksManagementService.authorizedPages);
    return this.getMenu(true, role);
  }
}

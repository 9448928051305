export type LocalStorageKeysTypes = LocalStorageEquipmentKeysTypes | LocalStorageInterventionKeysTypes | 'LAST_SITE_TAB' | 'LAST_USERS_TAB' | 'LAST_INTERVENTIONS_TAB' | 'SCADA_BUILDING_ID' | 'CLIENT_ID' | 'X_CLIENT_ID' | 'THEME' | 'AUTHENTICATED_USER' | 'CURRENT_SITE_ID' |'USED_LANGUAGE' | 'EQUIPMENT_CONTROLS_FILTERS' ;
export const LocalStorageKeysEnum = {
  AUTHENTICATED_USER: 'AUTHENTICATED_USER' as LocalStorageKeysTypes,
  LAST_SITE_TAB: 'LAST_SITE_TAB' as LocalStorageKeysTypes,
  LOGGED_USER_ID: 'LOGGED_USER_ID' as LocalStorageKeysTypes,
  LAST_USERS_TAB: 'LAST_USERS_TAB' as LocalStorageKeysTypes,
  LAST_INTERVENTIONS_TAB: 'LAST_INTERVENTIONS_TAB' as LocalStorageKeysTypes,
  CLIENT_ID: 'CLIENT_ID' as LocalStorageKeysTypes,
  X_CLIENT_ID: 'X_CLIENT_ID' as LocalStorageKeysTypes,
  THEME: 'THEME' as LocalStorageKeysTypes,
  CURRENT_SITE_ID: 'CURRENT_SITE_ID' as LocalStorageKeysTypes,
  USED_LANGUAGE: 'USED_LANGUAGE' as LocalStorageKeysTypes,
  EQUIPMENT_CONTROLS_FILTERS: 'EQUIPMENT_CONTROLS_FILTERS' as LocalStorageKeysTypes
}

export type LocalStorageEquipmentKeysTypes = 'SCADA_BUILDING_ID' | 'LAST_SELECTED_CONTROL_FLOOR' | 'LAST_SELECTED_CONTROL_CATEGORY';
export const LocalStorageEquipmentKeysEnum = {
  SCADA_BUILDING_ID: 'SCADA_BUILDING_ID' as LocalStorageKeysTypes,
  LAST_SELECTED_CONTROL_FLOOR: 'LAST_SELECTED_CONTROL_FLOOR' as LocalStorageKeysTypes,
  LAST_SELECTED_CONTROL_CATEGORY: 'LAST_SELECTED_CONTROL_CATEGORY' as LocalStorageKeysTypes,
}

export type LocalStorageInterventionKeysTypes = 'INTERVENTIONS_FILTERS';
export const LocalStorageInterventionKeysEnum = {
  INTERVENTIONS_FILTERS: 'INTERVENTIONS_FILTERS' as LocalStorageInterventionKeysTypes
}


export const SigningOutKeys: LocalStorageKeysTypes[] = [
  LocalStorageKeysEnum.CLIENT_ID,
  LocalStorageKeysEnum.AUTHENTICATED_USER,
  LocalStorageKeysEnum.CURRENT_SITE_ID,
  LocalStorageKeysEnum.THEME,
  LocalStorageEquipmentKeysEnum.SCADA_BUILDING_ID,
  LocalStorageEquipmentKeysEnum.LAST_SELECTED_CONTROL_FLOOR,
  LocalStorageEquipmentKeysEnum.LAST_SELECTED_CONTROL_CATEGORY,
]

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {forkJoin, Observable} from 'rxjs';
import {OutDoorTemperaturePoint} from '../../models/energy/out-door-temperature-point';
import {WeatherTemperature} from '../../models/weather/weather-temperature';
import {catchError, map} from 'rxjs/operators';
import {Energy} from '../../models/energy/enums/energy.name-space';
import {UserSpace} from '../../models/user-access/user.enum';
import {LocalStorageService} from '../localstorage/local-storage.service';
import {WeatherTemperatureFieldsEnum} from '../../models/weather/weather-temperature-fields';
import {CityWeather} from '../../models/weather/city-weather.model';
import {ForecastWeather} from '../../models/weather/forecast-weather.model';
import {ErrorHandlerService} from '../error-handler/error-handler.service';
import {WeatherCities} from '../../models/weather/weather-cities';
import {AuthenticationsService} from "../auth/authenticationsService";

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  private weatherRawPath = `/api/sg-weather/raw_weather/`;
  private weatherDailyPath = `/api/sg-weather/daily_weather/`;
  private endpointCurrent = '/api/openweathermap/data/2.5/weather?';
  private endpointForecast = '/api/openweathermap/data/2.5/forecast?';


  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private authService: AuthenticationsService,
    private errorHandle: ErrorHandlerService,
    private datePipe: DatePipe) {
  }


  getBuildingTemperatureRecords(weatherStation: string, startDate: Date, endDate, period: Energy.EnergyPeriodTypes, fields: WeatherTemperatureFieldsEnum[]): Observable<OutDoorTemperaturePoint[]> {
    let url: string = this.weatherDailyPath + weatherStation;
    let httpParams = new HttpParams().append('start_date', this.datePipe.transform(startDate, 'yyyy-MM-dd\'T\'HH:mm:ss'))
      .append('end_date', this.datePipe.transform(endDate, 'yyyy-MM-dd\'T\'HH:mm:ss'))
      .append('fields', fields.toString())

    let periodName: string;
    switch (period) {
      case Energy.EnergyPeriodEnum.YEAR:
        periodName = 'yearly';
        break;
      case Energy.EnergyPeriodEnum.MONTH:
        periodName = 'monthly';
        break;
      case Energy.EnergyPeriodEnum.DAY:
        periodName = 'daily';
        break;
      case Energy.EnergyPeriodEnum.HOUR:
        return this.getOutdoorTemperatureRecords(weatherStation, startDate, endDate, 60);
    }
    httpParams = httpParams.append('period', periodName);

    return this.http.get<WeatherTemperature>(url, {params: httpParams}).pipe(
      catchError(e => this.errorHandle.handleError),
      map(data => {
        return data.weather_data.map(record => ({
          ...record,
          value: record.temp_mean,
          time: new Date(record.date)
        }));
      }));
  }

  getOutdoorTemperatureRecords(weatherStation: string, startDate: Date, endDate, frequency: number): Observable<OutDoorTemperaturePoint[]> {
    let url = this.weatherRawPath + weatherStation;
    const token: string = this.authService.getToken();
    let headers = {'Authorization': 'Bearer ' + token};
    let httpParams = new HttpParams().append('start_date', this.datePipe.transform(startDate, 'yyyy-MM-dd'))
      .append('end_date', this.datePipe.transform(endDate, 'yyyy-MM-dd'))
      .append('fields', 'temperature')
      .append('frequency', frequency + 'min');
    return this.http.get<WeatherTemperature>(url, {params: httpParams, headers: headers}).pipe(
      catchError(e => this.errorHandle.handleError),
      map(data => {
        return data.weather_data.map(record => ({
          value: record.temperature,
          time: new Date(record.date)
        }));
      }));
  }

  /**
   * Fetches weather data by City.
   * @param cityCountry
   * @param current
   */
  getWeatherByCity(cityCountry: string, current?: boolean): Observable<CityWeather> {
    const endpoint = (current) ? (this.endpointCurrent) : (this.endpointForecast);
    const path = `${endpoint}q=${cityCountry}&units=metric`;
    const headers = new HttpHeaders().append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*');
    return this.http.get(path, {headers}).pipe(catchError(e => this.errorHandle.handleError));
  }

  getWeatherByCoordinates(lat: string, lon: string, current?: boolean): Observable<CityWeather> {
    const endpoint = (current) ? (this.endpointCurrent) : (this.endpointForecast);
    const path = `${endpoint}lat=${lat}&lon=${lon}&units=metric`;
    const headers = new HttpHeaders().append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*');
    return this.http.get(path, {headers}).pipe(catchError(e => this.errorHandle.handleError));
  }

  getWeatherForecast(cityCountry: string): Observable<ForecastWeather> {
    const path = `${this.endpointForecast}q=${cityCountry}&units=metric`;
    const headers = new HttpHeaders().append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*');
    return this.http.get(path, {headers}).pipe(catchError(e => this.errorHandle.handleError));
  }

  getWeatherForecastByCoordinates(lat: string, lon: string): Observable<ForecastWeather> {
    const path = `${this.endpointForecast}lat=${lat}&lon=${lon}&units=metric`;
    const headers = new HttpHeaders().append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*');
    return this.http.get(path, {headers}).pipe(catchError(e => this.errorHandle.handleError));
  }

  /**
   * Fetch the weather for all the cities in the list.
   */
  getWeatherForAllCities(): Observable<CityWeather[] | ForecastWeather> {
    const observablesList: Observable<CityWeather>[] = [];
    WeatherCities.forEach((item: string) => {
      observablesList.push(this.getWeatherByCity(item, true));
    });
    return forkJoin(observablesList);
  }
}

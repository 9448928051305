<div class="main-toolbar-component w-100p f-col a-center j-start">
  <div class="header-container">
    <section>
      <button (click)="toggleDrawer('L')">
        <mat-icon class="sg-font-primary">menu</mat-icon>
      </button>
      <div class="logo-container">
        <ng-container *ngTemplateOutlet="isSmartkizLogo? smartkizLogo : twoLogo"></ng-container>
      </div>
    </section>
    <section>
      <mat-icon id="menuSettings-icon">{{pageIcon}}</mat-icon>
      <span id="menuSettings-title">{{viewPage? currentBuilding?.name : pageTitle ?(('PAGES_TITLES.' + pageTitle) | translate | capitalize) : ''}}</span>
    </section>
    <section>
      <ng-container *ngTemplateOutlet="clientId == 'imagine' ? imagineClient: notImagineClient"></ng-container>
      <gmao-small-screens-menu [navLinks]="navLinks"></gmao-small-screens-menu>
      <mat-slide-toggle [checked]="checked" (change)="toggleEditMenu($event)"
                        id="slide-toggle"
                        *ngIf="currentAccessStatus[2]"
                        [hidden]="!menuSettings?.editMenu?.showButton"></mat-slide-toggle>
      <button mat-button (click)="toggleTheme()">
        <ng-container *ngTemplateOutlet="getCurrentTheme()==='light' ? isLight : isDark"></ng-container>
      </button>
      <button mat-button
              *ngIf="menuSettings?.infoMenu?.showButton"
              [disabled]="isControlOrHomePage && !checked"
              (click)="toggleDrawer('R')">
        <mat-icon class="sg-font-primary">info_outline</mat-icon>
      </button>
    </section>
  </div>
</div>

<ng-template #twoLogo>
  <div class="logo" [ngClass]="{'two-img': secondLogo}"></div>
  <div class="logo2" *ngIf="secondLogo" id="secondLogo"></div>
</ng-template>
<ng-template #smartkizLogo>
  <div class="smartkiz-logo"></div>
</ng-template>


<ng-template #imagineClient>
  <div id="emergency">
    {{'emergency'|translate |capitalize}} : 01 42 75 44 44
  </div>
</ng-template>
<ng-template #notImagineClient>
  <div id="date-now">
    {{ now | date: 'EEEE d MMMM y - HH:mm' }}
  </div>
</ng-template>


<ng-template #isLight>
  <mat-icon class="sg-font-primary">dark_mode</mat-icon>
</ng-template>
<ng-template #isDark>
  <mat-icon class="sg-font-primary">light_mode</mat-icon>
</ng-template>

<mat-sidenav-container #drawer class="w-100p h-100p no-scroll" autosize>

  <mat-sidenav #left mode="side" class="side-menu py-10px h-100p  no-scroll f-col a-center j-between bxs-black z-index-999 side-nav-links sg-bgr-primary"
               id="side-nav" [opened]="!isFullScreen" [disableClose]="true" *ngIf="(authService.isAuthenticatedObs | async) && !isStandalone"
               [ngClass]="{'menu-collapsed mobile-hide': !menuSettings?.navMenu?.expanded, 'menu-expanded': menuSettings?.navMenu?.expanded}">
    <gmao-sidenav-link [links]="menuLinksManagementService.loggedInUserAuthorizedMenuObs | async" [isExpanded]="menuSettings?.navMenu?.expanded"></gmao-sidenav-link>
    <div class="term-condition-container w-100p f-col a-center j-center">

      <div *ngIf="menuSettings?.navMenu?.expanded" class="h-30px f-row a-center j-center txt-darkgray">
        {{"version " | translate}} {{currentVersion}} </div>
      <div *ngIf="!menuSettings?.navMenu?.expanded"
           class="h-30px f-row a-center j-center txt-darkgray txt-bold"> v{{currentVersion}} </div>

      <a *ngIf="menuSettings?.navMenu?.expanded" class="txt-darkgray"
         href="{{metions}}"> Mentions légales </a>
      <div *ngIf="menuSettings?.navMenu?.expanded" class=" txt-darkgray"> Tous droits réservés</div>

      <img *ngIf="!menuSettings?.navMenu?.expanded" src="/assets/default-logos/smartglobe/Smartglobe-G.svg"/>
      <img *ngIf="menuSettings?.navMenu?.expanded" src="../../../assets/Smart-Globe.png"/>
    </div>

    <ng-content></ng-content>

  </mat-sidenav>


  <mat-sidenav-content #content class="mat-sidenav-content"
                       [ngClass]="{'w-mob-100p m-mob-0':!menuSettings?.navMenu?.expanded,
                       'no-left-margin':isFullScreen || isStandalone,
                       'no-padding':firstView || !(authService.isAuthenticatedObs | async)}"

                       (click)="(menuSettings?.navMenu?.expanded)?closeMenu():''">
    <div class="loading-shade" *ngIf="menuSettings?.navMenu?.expanded"></div>
    <div class="f-col h-100p no-scroll sg-bgr-primary br-5px"
         [class.no-padding]="firstView || !(authService.isAuthenticatedObs | async)">
      <div class="mobile-hide w-100p no-scroll sg-bgr-primary navigation-section" *ngIf="menuSettings?.infoMenu?.showButton && !isStandalone">
        <gmao-navigation-v2 [withSideInfo]="menuSettings?.infoMenu?.expanded"></gmao-navigation-v2>
      </div>

      <div class="w-100p h-100p no-scroll">
        <router-outlet></router-outlet>
      </div>
      <div class="loading-shade" *ngIf=isShowingRouteLoadIndicator>
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </mat-sidenav-content>

  <mat-sidenav #right mode="side" class="h-100p no-scroll f-col a-center j-between right-side-nav"
               [opened]="menuSettings?.infoMenu?.expanded" position="end">

    <div class="h-100p bgr-white">
      <smart-globe-bar></smart-globe-bar>
    </div>

  </mat-sidenav>

</mat-sidenav-container>

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/timeout';
import {environment} from 'src/environments/environment';
import {LocalStorageKeysEnum} from "../localstorage/local-storage-keys.enum";
import {AuthenticationParams} from "../../models/auth/authentication-params";
import {UserSpace} from "../../models/user-access/user.enum";
import COOKIE_TOKEN = UserSpace.COOKIE_TOKEN;

@Injectable()
export class InterceptorService implements HttpInterceptor {
  mapSettings: any;

  constructor(private cookieService: CookieService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params: { token: string, locale: string } = this.getParamsFromLocalStorage()
    this.mapSettings = environment.mapConfig;
    let X_CLIENT_ID: string = this.cookieService.get(LocalStorageKeysEnum.X_CLIENT_ID);
    if (request.url && request.url.length > 40 && request.url.slice(0, 40) == this.mapSettings.apiServer.baseURL) {
      request = request.clone({});
    } else {
      let header = {
        // 'Content-Type': 'application/json',
        // Accept: 'application/json',
        Authorization: `Bearer ${this.cookieService.get(COOKIE_TOKEN) || ''}`,
        Locale: params.locale || ''
      }

      X_CLIENT_ID && (header['X-CLIENT-ID'] = X_CLIENT_ID);


      request = request.clone({
        setHeaders: header
      });
    }
    return next.handle(request);

  }

  private getParamsFromLocalStorage() {
    let params: AuthenticationParams = JSON.parse(localStorage.getItem(LocalStorageKeysEnum.AUTHENTICATED_USER));
    return {token: this.cookieService.get(COOKIE_TOKEN), locale: params?.language}
  }

}

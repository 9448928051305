export namespace UserSpace {
  export type UserRoleType = 0 | 1 | 2 | 3 | 5;
  export const UserRoleEnum = {
    SUPER_ADMIN: 0 as UserRoleType,
    ADMIN: 1 as UserRoleType,
    OPERATOR: 2 as UserRoleType,
    USER: 3 as UserRoleType,
    SCREEN: 5 as UserRoleType,
  };

  export const UserRoleNamesEnum = {
    0: 'SUPER_ADMIN',
    1: 'ADMIN',
    2: 'OPERATOR',
    3: 'USER',
    5: 'SCREEN',
  };

  export type LanguagesTypes = 'fr' | 'en-gb';
  export const LanguagesEnum = {
    'ENGLISH': 'en-gb' as LanguagesTypes,
    'FRENCH': 'fr' as LanguagesTypes,
  };

  export type GenderTypes = 'MALE' | 'FEMALE';
  export const GenderEnum = {
    'MALE': 'MALE' as LanguagesTypes,
    'FEMALE': 'FEMALE' as LanguagesTypes,
  };

  export const GenderTitleEnum = {
    'MALE': 'Mr' as LanguagesTypes,
    'FEMALE': 'Mrs' as LanguagesTypes,
  };


  export type AuthedUserAttributes = 'ROLE' | 'CLIENT' | 'LOGIN' | 'NAME' | 'ID' | 'ACCESS_GROUPS' | 'LANGUAGE';
  export const AuthedUserAttributesEnum = {
    ROLE: 'roleId' as AuthedUserAttributes,
    CLIENT: 'clientId' as AuthedUserAttributes,
    LOGIN: 'login' as AuthedUserAttributes,
    NAME: 'name' as AuthedUserAttributes,
    ID: 'id' as AuthedUserAttributes,
    LANGUAGE: 'language' as AuthedUserAttributes,
    ACCESS_GROUPS: 'groups' as AuthedUserAttributes,
  }

  export const COOKIE_TOKEN = "authToken"


}

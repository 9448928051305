import {Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Subject, Subscription} from 'rxjs';
import {SideBarType} from 'src/app/core/services/sidebar/sidebar-type.enum';
import {DashboardService} from 'src/app/core/services/dashboard/dashboard.service';
import {SideBarService} from 'src/app/core/services/sidebar/sidebar.service';
import {GmaoGridsterComponent} from 'src/app/components/gmao-gridster/gmao-gridster/gmao-gridster.component';
import {ToggleMenuService} from '../content/toggle-menu.service';
import {
  AccessControlService
} from 'src/app/core/deprecated/deprecated-services/deprecated-access/access-control.service';
import {ITabItem} from 'src/app/app.component';
import {LocalStorageKeysEnum} from '../../../core/services/localstorage/local-storage-keys.enum';
import {AuthenticationsService} from '../../../core/services/auth/authenticationsService';
import {ActivatedRoute, ActivationEnd, ActivationStart, Router} from '@angular/router';
import {AppService} from "../../../core/services/app/app.service";
import {DomainNames} from "../../../core/models/app/enums/domain-names.enum";
import {UserSpace} from "../../../core/models/user-access/user.enum";
import AuthedUserAttributesEnum = UserSpace.AuthedUserAttributesEnum;


@Component({
  selector: 'sg-header',
  templateUrl: './sg-header.component.html',
  styleUrls: ['./sg-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class SgHeaderComponent implements OnInit, OnDestroy {
  @Input() navLinks: ITabItem[];
  private subscription: Subscription;
  pageTitle: string = '';
  pageIcon: string = '';
  isHamburguer = true;
  checked = false;
  disabled = true;
  notificationsNumber = 11;
  sideBarType;
  isControlOrHomePage: boolean = false;

  viewPage: boolean = false;


  menuSettings: any = {
    title: {
      icon: '',
      text: ''
    },
    navMenu: {
      showButton: true,
      expanded: false
    },
    infoMenu: {
      showButton: true,
      expanded: false,
      enum: -1
    },
    editMenu: {
      showButton: false
    },
    selectedPath: {
      i: 0,
      j: -1
    }
  };

  /**access */
  currentAccessStatus: boolean[] = [false, false, false];

  public now: Date = new Date();
  currentBuilding: any = {views: []};

  secondLogo = false;
  clientId;
  gridsterComponent: GmaoGridsterComponent;
  @Input() menuSettingsObservable: Subject<any[]>;

  isSmartkizLogo: boolean = false;

  constructor(
    private readonly authService: AuthenticationsService,
    private readonly homeNavService: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly sideBarService: SideBarService,
    private readonly accessService: AccessControlService,
    readonly accessControlService: AccessControlService,
    @Inject(ToggleMenuService) private toggleMenuService: ToggleMenuService,
    private appService: AppService
  ) {
    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  ngOnInit() {
    this.subscribeRoutingChanges();

    this.checkSmartkizLogo();

    //TODO: views shall be requested and handled using a service
    this.homeNavService.dashboardSelectedSiteObs.subscribe((building: any) => {
      this.currentBuilding = building;
      this.navLinks = this.accessService.filterViewsToRead(this.currentBuilding?.views);
      this.accessService.userAccessUpdated.subscribe((user) => {
        this.navLinks = this.accessService.filterViewsToRead(this.currentBuilding?.views);
      }, error => {
        console.log(error);
      });
    });


    this.accessControlService.currentAccessStatus.subscribe(
      (accessStatus: any[]) => {
        if (+this.authService.getAuthedUserData(AuthedUserAttributesEnum.ROLE) < 1) {
          this.currentAccessStatus = [true, true, true];
        } else {
          this.currentAccessStatus = accessStatus;
        }
      }, error => {
        console.log(error);
      });

    this.clientId = this.authService.getClientId();

    if (this.authService.getClientId() == 'imagine') {
      this.secondLogo = true;
    }


    this.menuSettingsObservable.subscribe((resSettings) => {
      if (resSettings instanceof Array) {
        this.menuSettings = {
          navMenu: {
            showButton: true,
            expanded: false
          }
        };
      } else {
        this.menuSettings = resSettings;
        if (this.menuSettings.title.icon === 'map' || this.menuSettings.title.icon === 'location_on') {
          this.homeNavService.dashboardSelectedSiteObs.subscribe((building: any) => {
            if(!building) return;
            this.currentBuilding = building;
            console.log("this.menuSettings.",this.menuSettings)

            this.menuSettings.title.text = building?.name;
          }, error => {
            console.log(error);
          });
        }
      }
    });

    this.sideBarService.hideEditButtonObs.subscribe((value: boolean) => {
      if(value == null) return;
      this.menuSettings = {
        ...this.menuSettings,
        editMenu: {
          showButton: value
        }
      };
      this.checked = false;
      this.toggleMenuService.sendAction(false)
    })

  }

  private checkSmartkizLogo() {
    if (this.appService.getDomainName()?.includes(DomainNames.SMARTKIZ)) {
      this.isSmartkizLogo = true;
    }
  }

  private subscribeRoutingChanges() {
    this.pageTitle = this.activatedRoute?.firstChild?.data?.['value']?.['title'];
    this.viewPage = this.activatedRoute?.firstChild?.data?.['value']?.['featureType'] == 'VIEW';
    this.pageIcon = this.activatedRoute?.firstChild?.data?.['value']?.['icon'];
    this.checkRouting();
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.checked = false;
        this.checkRouting();
        this.pageTitle = event.snapshot.data['title'];
        this.pageIcon = event.snapshot.data['icon'];
        this.viewPage = this.activatedRoute.firstChild.snapshot.data['featureType'] == 'VIEW';
      }
    });
  }

  private checkRouting() {
    this.isControlOrHomePage =
      (this.router.url.includes('dashboard') && !this.router.url.includes('/t/') && !this.router.url.includes('/w/'))
      || this.router.url.includes('equipment-scada')
      || this.router.url.includes('equipment-control');
  }

  public toggleEditMenu(event: MatSlideToggleChange) {
    this.checked = event.checked;

    this.toggleMenuService.sendAction(this.checked);

    if (this.checked) {
      this.sideBarService.getSideBarTypeObservable().subscribe((result: SideBarType) => {
        if (result !== 9) {
          this.sideBarType = result;
        }
      }, error => {
        console.log(error);
      });
      (this.sideBarType == SideBarType.EQUIPMENTCONTROL) ? (this.sideBarService.changeSideBarType(SideBarType.EQUIPMENTCONTROL)) : (this.sideBarService.changeSideBarType(SideBarType.TOOLBAR));
    } else {
      this.sideBarService.changeSideBarType(this.sideBarType);
    }
  }

  toggleDrawer(side) {
    if (side == 'L') {
      this.menuSettings.navMenu.expanded = !this.menuSettings.navMenu.expanded;
    } else {
      this.menuSettings.navMenu.expanded = false;
      this.menuSettings.infoMenu.expanded = !this.menuSettings.infoMenu.expanded;
    }
  }

  getCurrentTheme() {
    return localStorage.getItem(LocalStorageKeysEnum.THEME);
  }

  toggleTheme() {
    this.getCurrentTheme() == 'light' ? localStorage.setItem(LocalStorageKeysEnum.THEME, 'dark') : localStorage.setItem(LocalStorageKeysEnum.THEME, 'light');

    window.location.reload();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
